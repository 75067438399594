.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  line-height: 1.44444444;
  cursor: pointer;
  color: #868686;
  padding: 11px 46px 11px 18px;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-decoration: none;
  text-align-last: left;
  border: 1px solid #868686;
  min-width: 200px;
  transition: all 0.3s;
  background-size: 10px 18px;
  background-position: right 18px center;
  background-repeat: no-repeat;
  background-image: url(/images/corner-right-gray.svg);
}
.button:hover,
.button:focus {
  color: #000;
  background-color: #C8EA8E;
  border-color: #C8EA8E;
  background-image: url(/images/corner-right-black.svg);
}
@media (max-width: 1023px) {
  .button {
    min-width: 140px;
  }
}
.desk {
  max-width: calc(100% - 80px);
}
.section--one .desk {
  width: 604px;
}
.homelink {
  margin-left: 40px;
}
@media (max-width: 1023px) {
  .footcontent {
    padding: 0 82px;
  }
  .footpart--verband {
    width: 204px;
    margin-top: 0;
    margin-left: auto;
  }
  .vcard {
    width: 204px;
  }
  .footlogo {
    margin-left: -42px;
  }
  .footlogo--verband {
    margin-left: 0;
    margin-top: 26px;
    padding-bottom: 26px;
  }
  .footlogo--verband img {
    max-width: 120%;
  }
  .credits {
    padding: 0 40px;
  }
}
@media (max-width: 1023px) and (max-width: 921px) {
  .footpart--verband {
    margin-left: 0;
    margin-top: 50px;
  }
}
@media (max-width: 1023px) and (max-width: 921px) {
  .footlogo--verband {
    max-width: unset;
    margin-left: -42px;
  }
  .footlogo--verband img {
    max-width: 120%;
  }
}
.headercontent {
  padding: 22px 0;
}
.homelink {
  height: 86px;
}
@media (max-width: 1023px) {
  h2 {
    font-size: 38px;
    line-height: 1.10526316;
  }
}
.section--two .area .pure.wide div.line {
  margin-top: 44px;
  margin-bottom: 44px;
}
.section--two .area .fold + .fold {
  margin-top: -10px;
}
.section--two .area {
  width: 100%;
}
.section--two .area > .unit {
  margin-right: 2.61707989%;
  margin-left: 2.61707989%;
  width: 94.76584022%;
}
.section--two .area .cb-album .body,
.section--two .area .head,
.section--two .area .foot,
.section--two .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim {
  width: 44.76584022%;
}
.section--two .area > .slim .head,
.section--two .area > .slim .foot,
.section--two .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim .tiny {
  width: 100%;
}
.section--two .area {
  width: 105.52325581%;
  margin-left: -2.76162791%;
}
.section--two .area .pure.wide .body {
  width: 105.52325581%;
  margin-left: -2.76162791%;
}
.section--two .area .pure.wide .part {
  margin-left: 2.61707989%;
  margin-right: 2.61707989%;
  width: 94.76584022%;
}
.section--two .area .pure.wide .part.tiny {
  width: 44.62809917%;
}
.section--two .area .seam {
  width: 28.09917355%;
}
.section--two .area .fold {
  width: 94.76584022%;
}
.section--two .area .fold .more .part {
  margin-left: 10px !important;
  margin-right: 10px !important;
  width: calc((100% - 20px)) !important;
}
.section--two .area .fold .more .part.tiny {
  width: calc(((100% - 60px) / 3)) !important;
}
.section--two .area .fold .more .part.line {
  width: calc(100% + 20px) !important;
  margin-left: -10px !important;
  margin-right: 0 !important;
}
/*# sourceMappingURL=./screen-medium.css.map */